import { sleep } from '../modules/common';

export async function fetchOnce(url, options) {
    if (options == undefined) options = {};
    applySafariFixToOptions(options);

    let result = await fetch(url, options);
    return result
}

export async function fetchRetry(url, options, amountOfRetries, backoffInSeconds, maximumBackoffInSeconds = 0) {
    for (let i = 0; i < amountOfRetries; i++) {
        try {
            let result = await fetchOnce(url, options);
            if (!result.ok) throw Error('no ok state form server');
            if (i > 0) console.log('fetchRetry recovered!');
            return result
        } catch (err) {
            console.log('fetchRetry fehlgeschlagen. Versuch Nr : ' + (i + 1));

            const isLastAttempt = i + 1 === amountOfRetries;
            if (isLastAttempt) throw err;

            await sleep(backoffInSeconds);
            if (maximumBackoffInSeconds == 0 || maximumBackoffInSeconds > backoffInSeconds)
                backoffInSeconds = backoffInSeconds * 2;
        }
    }
};

//Bridges a stupid error in Safari 11.1 that messes up session cookie on calls.
function applySafariFixToOptions(options) {
    
    options.credentials = 'same-origin';
}